import * as React from 'react'
import Layout from '../components/layout'

const ContactPage = () => {
    return (
    <Layout pageTitle="Contact">
        <section className="featured-area">
            <div className="container">
            <div className="row">
                <div className="col-sm-12">
                    <blockquote className="generic-blockquote">
                        <div className="row">
                            <div className="col-sm-6">
                                <ul className="fa-ul">
                                    <li><i className="fa-li fa fa-envelope"></i><a href="mailto:info@pand99.be">info@pand99.be</a></li>
                                    <li><i className="fa-li fa fa-phone"></i><a href="tel:003252222998">+32 52 22 29 98</a></li>
                                    <li><i className="fa-li fa fa-mobile"></i><a href="tel:0032498662781">+32 498 66 27 81</a></li>
                                    <li><i className="fa-li fa fa-road"></i>Sint-Gillislaan 99, 9200 Dendermonde</li>
                                    <li><i className="fa-li fa fa-clock-o"></i>8-22u</li>
                                </ul>
                            </div>
                            <div className="col-sm-6">
                                <ul className="fa-ul">
                                    <li><i className="fa-li fa fa-credit-card-alt"></i><strong>BNP Paribas</strong> BE97 0017 8203 0749</li>
                                    <li><i className="fa-li fa fa-credit-card-alt"></i><strong>KBC</strong> BE36 7370 3575 8081</li>
                                    <li><i className="fa-li fa fa-hospital-o"></i>RPR Gent, Afdeling dendermonde</li>
                                    <li><i className="fa-li fa fa-building"></i>BE0844.549.306</li>
                                </ul>
                            </div>
                        </div>
                    </blockquote>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <iframe id="google-maps" className="maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2509.7827550507996!2d4.107982615752153!3d51.02016217955812!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c396b591e30213%3A0xf064e974f1cc60d9!2sPand+99!5e0!3m2!1snl!2sus!4v1501669862197" height="450" title="Google Maps" frameBorder="0" style={{border:0}} allowFullScreen="allowFullScreen"></iframe>
                </div>
            </div>
		    </div>
	    </section>
    </Layout>
    )
}

export default ContactPage